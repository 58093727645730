import React from 'react';
import { defineMessages, t } from '@sm/intl';

import NonBrandedEndpageHeader from '~app/components/NonBrandedEndpageHeader';

const COPY = defineMessages({
  NONBRANDED_ENDPAGEHEADER_COPY: {
    id: 'SurveyTaken.NonBrandedSurveyTaken.NonBrandedEndpageHeader',
    defaultMessage: 'You have already taken this survey',
    description: '[Type: Header][Vis: high] - Informs the viewer that this survey has been taken',
  },
});

const NonBrandedSurveyTaken = (): React.ReactElement => {
  return <NonBrandedEndpageHeader copy={t(COPY.NONBRANDED_ENDPAGEHEADER_COPY)} />;
};

export default NonBrandedSurveyTaken;
