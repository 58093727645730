import React from 'react';
import { defineMessages, t } from '@sm/intl';

import BrandedEmbeddedHeader from '~app/components/BrandedEmbeddedHeader';

const COPY = defineMessages({
  BRANDED_EMBEDDED_COPY: {
    id: 'SurveyTaken.BrandedEmbeddedSurveyTaken.DefaultMessage',
    defaultMessage: 'You have already taken this survey.',
    description: '[Type: Header][Vis: high] - Informs the viewer that this survey has been closed',
  },
});

export type Props = {
  message?: string;
};

const BrandedEmbeddedSurveyTaken = ({ message = t(COPY.BRANDED_EMBEDDED_COPY) }: Props): React.ReactElement => (
  <BrandedEmbeddedHeader message={message} utSource="survey-taken" />
);

export default BrandedEmbeddedSurveyTaken;
