import React, { useEffect } from 'react';
import { GetServerSidePropsResult } from 'next';
import { ThemeProvider } from 'react-jss';
import Head from 'next/head';
import { t, defineMessages } from '@sm/intl';
import { MetricsTracker, USER_EVENTS } from '@sm/metrics';

import routeHandler from '~helpers/routeHandler';
import surveyTaken, { SurveyTakenServerSideData } from '~helpers/pages/survey-taken';
import { SmParams, PageWithLayout } from '~helpers/pages/types';
import { buildSmOptions } from '~helpers/buildSmOptions';
import fetchLocaleMessages from '~helpers/fetchLocaleMessages';

import theme, { useGlobalStyles } from '~app/styles/cmsTheme';
import BrandedSurveyTaken from '~app/pages/SurveyTaken/BrandedSurveyTaken';
import EUBrandedSurveyTaken from '~app/pages/SurveyTaken/EUBrandedSurveyTaken';
import NonBrandedSurveyTaken from '~app/pages/SurveyTaken/NonBrandedSurveyTaken';
import BrandedEmbeddedSurveyTaken from '~app/pages/SurveyTaken/BrandedEmbeddedSurveyTaken';
import EmbeddedNonBrandedHeader from '~app/components/EmbeddedNonBrandedHeader';
import { CustomGSSPContext, LayoutData } from '~helpers/middleware/types';
import OneTrustFooterScript from '~components/oneTrustScript';
import PageLayout from '~components/pageLayout';

const COPY = defineMessages({
  DEFAULT_TAKEN_MESSAGE: {
    id: 'survey-taken.takenMessage',
    defaultMessage: 'You have already taken this survey.',
  },
  TAKEN_PAGE_TITLE: {
    id: 'survey-taken.title',
    defaultMessage: 'Free Online Survey Software by SurveyMonkey: Survey Taken',
  },
  TAKEN_PAGE_NON_BRANDED_TITLE: {
    id: 'survey-taken.nonBrandedTitle',
    defaultMessage: 'Online Survey Software: Survey Taken',
  },
});

const fallbackCollectorData = {
  collector: {
    id: null,
    type: null,
    hideBranding: true,
  },
};

type PageVariant = 'branded' | 'nonbranded' | 'brandedEU' | 'embeddedNonBranded' | 'embeddedBranded';

type ServerSideProps = LayoutData & {
  canonical: string;
  canonicalHostname: string;
  pageVariant: PageVariant;
  showFooter: boolean;
  smParams?: SmParams;
};

// does not use CustomGetServerSideProps<ServerSideProps> types as it needs pageProps but does not return it
export const getServerSideProps = async ({
  req,
  res,
  query,
  resolvedUrl,
}: CustomGSSPContext<ServerSideProps & SurveyTakenServerSideData>): Promise<
  GetServerSidePropsResult<ServerSideProps>
> => {
  const handler = routeHandler({
    query,
    pageDataLoader: surveyTaken,
    owners: '@webplatform/content',
    onetrustBannerEnabled: true,
  });

  await handler.run(req, res);

  const smParams = buildSmOptions(query)?.smParams;
  const { collector } = req?.pageProps?.collectorData ?? fallbackCollectorData;

  const isNonBranded =
    req?.pageProps?.pageOptions?.isNonBrandedSmParam === true ||
    collector?.hideBranding ||
    req?.pageProps?.pageOptions?.isWhiteLabel;

  const isEmbedded = req?.pageProps?.pageOptions?.isEmbeddedSmParam || collector?.type?.toUpperCase() === 'POPUP';

  const showFooter = !(isNonBranded || isEmbedded);

  // TODO: related to the typing, this part needs re-visit
  const isEUSubdomain = req?.payloads?.staticData?.locale.isEUSubdomain === true;
  const { canonical = '', canonicalHostname = '' } = req?.pageProps?.pageOptions || {};

  // determine the variant of content to render
  let pageVariant: PageVariant = 'branded';
  if (isEmbedded) {
    pageVariant = isNonBranded ? 'embeddedNonBranded' : 'embeddedBranded';
  } else if (isNonBranded) {
    pageVariant = 'nonbranded';
  } else if (isEUSubdomain) {
    pageVariant = 'brandedEU';
  }

  // TODO: Retire the usage of StaticContext and use page props instead.
  // Additional page-specific (reCAPTCHA) data needed in StaticContext
  req.addClientPayload('staticData', {
    recaptchaV3: {
      enabled: process?.env?.SECURITY_RECAPTCHA_V3_ENABLED?.toLowerCase() === 'true' ?? false,
      siteKey: process?.env?.SECURITY_RECAPTCHA_V3_SITE_KEY ?? '',
      url: process?.env?.SECURITY_RECAPTCHA_V3_URL ?? '',
      urlChina: process?.env?.SECURITY_RECAPTCHA_V3_URL_CHINA ?? '',
      useEnterprise: process?.env?.SECURITY_RECAPTCHA_V3_USE_ENTERPRISE?.toLowerCase() === 'true' ?? false,
    },
    smParams,
    templateType: pageVariant,
  });

  const { slLanguageLocale, languageCode } = req.payloads?.staticData?.environment ?? {};
  const translationData = await fetchLocaleMessages(slLanguageLocale, languageCode);

  return {
    props: {
      // added to the request via routeHandler
      ...req.payloads,
      translationData,
      layout: {
        variant: 'SurveyMonkey',
        options: {
          pageId: 'Survey Taken',
          legacyWeb: 'anonweb',
          includeHeader: false,
          includeFooter: showFooter,
          includeGTM: true,
        },
      },
      canonical,
      canonicalHostname,
      pageVariant,
      showFooter,
      smParams,
    },
  };
};

const SurveyTaken: PageWithLayout<ServerSideProps> = ({ pageVariant, smParams, canonicalHostname }) => {
  useGlobalStyles();
  const defaultText: { [key: string]: string } = {
    default: t(COPY.TAKEN_PAGE_TITLE),
    embeddedNonBranded: t(COPY.TAKEN_PAGE_NON_BRANDED_TITLE),
    embeddedBranded: t(COPY.TAKEN_PAGE_TITLE),
    nonbranded: t(COPY.TAKEN_PAGE_NON_BRANDED_TITLE),
    isEU: t(COPY.TAKEN_PAGE_TITLE),
  };

  useEffect(() => {
    MetricsTracker.track({
      name: USER_EVENTS.PAGE_VIEW,
      data: {
        amplitudeEvent: 'page viewed',
        pageName: 'survey taken',
        templateType: pageVariant,
        surveyId: smParams?.surveyId,
        collectorId: smParams?.collectorId,
        respondentId: smParams?.respondentId,
        urlPath: window.location.pathname,
        app: 'respweb',
        log: {
          message: pageVariant,
        },
      },
    });
  }, [pageVariant, smParams]);

  const surveyTakenBody: React.JSX.Element = {
    branded: <BrandedSurveyTaken />,
    nonbranded: <NonBrandedSurveyTaken />,
    brandedEU: <EUBrandedSurveyTaken />,
    embeddedNonBranded: <EmbeddedNonBrandedHeader message={t(COPY.DEFAULT_TAKEN_MESSAGE)} icon="taken" />,
    embeddedBranded: <BrandedEmbeddedSurveyTaken message={t(COPY.DEFAULT_TAKEN_MESSAGE)} />,
  }[pageVariant as PageVariant] || <NonBrandedSurveyTaken />;

  return (
    <ThemeProvider theme={theme}>
      <Head>
        <base target="_top" href="/" />
        <link rel="canonical" href={`${canonicalHostname}/survey-taken/`} />
        <title>{defaultText[pageVariant]}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Head>
      {surveyTakenBody}
      <OneTrustFooterScript />
    </ThemeProvider>
  );
};

SurveyTaken.getLayout = function getLayout(page) {
  const { layout, staticData, translationData } = page.props;

  return (
    <PageLayout layout={layout} staticData={staticData} translationData={translationData}>
      {page}
    </PageLayout>
  );
};

export default SurveyTaken;
